.poi__map--height {
    height: 500px;
}

.poi__arrow--positioning {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
}

.poi__arrow--positioning-right {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
}

.poi__marker--translate {
    transform: translate(-50%, -100%);
}

.poi__options--positioning {
    width: 300px;
}

@media screen and (max-width: 767px) {
    .poi__options--positioning {
        z-index: 1;
        position: absolute;
        left: -300px;
        top: 0;
        transition: left ease-in 300ms;
    }

    .poi__options--positioning.active {
        left: 0;
    }
}