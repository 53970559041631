reel-l {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-color: var(--color-light) var(--color-dark);
}

reel-l > * {
    flex: 0 0 var(--item-width);
}

reel-l > img {
    height: 100%;
    flex-basis: auto;
    width: auto;
}

reel-l.reel--center > *:first-child {
    margin-left: auto;
}

reel-l.reel--center > *:last-child {
    margin-right: auto;
}

/* reel-l::-webkit-scrollbar {
    height: 1rem;
} */

/* reel-l::-webkit-scrollbar-track {
    background-color: var(--color-dark);
}

reel-l::-webkit-scrollbar-thumb {
    background-color: var(--color-dark);
    background-image: linear-gradient(
        var(--color-dark) 0,
        var(--color-dark) 0.25rem,
        var(--color-light) 0.25rem,
        var(--color-light) 0.75rem,
        var(--color-dark) 0.75rem
    );
} */
