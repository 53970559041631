html {
  box-sizing: border-box;

  font-size: 16px;
  font-family: 'Taviraj', serif;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

body {
  margin: 0;
  width: 100%;
  overflow-x: hidden!important;
}

/* #__next {
  overflow-x: hidden;
} */

* {
  box-sizing: inherit;
}


@media screen and (max-width: 1023px) {
  .hide-on-lg {
    display: none;
  }
}

.markdown-bulleted-list > ul {
  text-align: left;
  list-style-type: disc;
  list-style-position: inside;
}

.fancy-first::first-letter {
  font-size: 400%;
  line-height: 50%;
  font-stretch: condensed;
  font-weight: bold;
  float: left;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
}

.break-out-banner {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}


button:disabled {
    opacity: 50%;
    cursor: not-allowed;
}
